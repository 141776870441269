import React from "react"
import styled from "styled-components"

import Metadata from "../components/Metadata"
import Circuit from "../components/Circuit"
import ExerciseThumbnail from "../components/ExerciseThumbnail"
import FooterPitch from "../components/FooterPitch"

const extractText = (text, type) => {
  // const example = [
  //   {
  //     type: "text",
  //     text: { content: "10 Challenging Hanging Core Exercises", link: null },
  //     annotations: {
  //       bold: false,
  //       italic: false,
  //       strikethrough: false,
  //       underline: false,
  //       code: false,
  //       color: "default",
  //     },
  //     plain_text: "10 Challenging Hanging Core Exercises",
  //     href: null,
  //   },
  // ]
  //

  const items = text?.map(obj => {
    let textItem = obj.text?.content

    // Don't create html processing for headings to create correct table of contents
    if (!type?.includes("heading") && type !== "title") {
      /* Styles */
      if (obj.annotations?.bold) textItem = "<strong>" + textItem + "</strong>"
      if (obj.annotations?.italic)
        textItem = "<span style='font-style: italic'>" + textItem + "</span>"
      if (obj.annotations?.underline)
        textItem =
          "<span style='text-decoration: underline'>" + textItem + "</span>"

      /* State */
      if (obj.text?.link)
        textItem = `<a href="${obj.text.link.url}">${textItem}</a>`
    }

    return textItem
  })

  return items?.join(" ")
}

const handlePlugin = json => {
  // const plugin = code.match(/\(.*?\)/g).map(x => x.replace(/[()]/g, ""))
  const code = JSON.parse(json)

  if (code.plugin === "exercise") {
    return (
      <ExerciseContainer>
        <ExerciseThumbnail {...code.data} autoplay />
      </ExerciseContainer>
    )
  }

  if (code.plugin === "circuit") {
    return <Circuit {...code.data} />
  }
}

const Content = ({
  pageContext: {
    slug,
    title,
    desc,
    image,
    properties: {
      phone,
      email,
      instagram,
      linkedin,
      signature
    },
    blocks,
    contents,
    readTime,
    createdTime,
    lastEditedTime,
  },
  data,
  location,
}) => {
  // const formattedTitle = extractText(title[title.type], title.type)

  const scrollTo = id => {
    const el = document.getElementById(id)

    el?.scrollIntoView({
      behavior: "smooth",
      inline: "start",
    })
  }

  return (
    <>
      <Metadata
        title={title}
        description={desc}
        url={`https://programme.app/${slug}`}
        image={image}
      />

      <Container>
        <Body>

          <div>
            <img src={image} alt="Author"/>
          </div>

          <H1>{title}</H1>

          {contents.length ? 
            <TableOfContents>
              <span>On This Page</span>
              <ul>
                {contents.map(i => (
                  <ContentsItem indent={i.type === "heading_3"}>
                    <div
                      onClick={() =>
                        scrollTo(`${i.text.toLowerCase().replace(/ /g, "-")}`)
                      }
                    >
                      {i.text}
                    </div>
                  </ContentsItem>
                ))}
              </ul>
            </TableOfContents> 
          : null}

          {blocks.map(block => {
            const text = extractText(block[block.type].text, block.type)

            switch (block.type) {
              case "heading_2":
                return (
                  <h2
                    id={text.toLowerCase().replace(/ /g, "-")}
                    dangerouslySetInnerHTML={{ __html: text }}
                  />
                )
              case "heading_3":
                return (
                  <h3
                    id={text.toLowerCase().replace(/ /g, "-")}
                    dangerouslySetInnerHTML={{ __html: text }}
                  />
                )
              case "paragraph":
                return <p dangerouslySetInnerHTML={{ __html: text }} />
              case "bulleted_list_item":
              case "numbered_list_item":
                return <li dangerouslySetInnerHTML={{ __html: text }} />
              case "code":
                return handlePlugin(text)
              default:
                return <p dangerouslySetInnerHTML={{ __html: text }} />
            }
          })}

          <h3>Reach Out</h3>
          <p>
            {email ? <div><a href={`mailto:${email}`}>{email}</a></div> : null}
            {phone ? <div><a href={`tel:${phone}`}>{phone}</a></div> : null}
            {instagram ? <div><a href={`https://instagram.com/${instagram}`}>Instagram</a></div> : null}
            {linkedin ? <div><a href={`https://linkedin.com/u/${linkedin}`}>LinkedIn</a></div> : null}
          </p>
        </Body>

        <Signature>
          <h3>{title}</h3>
          <img src={signature} alt="Signature" />
        </Signature>

      </Container>
      <FooterPitch />
    </>
  )
}

const Container = styled.div`
  padding: 120px 2em;

  @media (max-width: 640px) {
    padding: 0 1em;
    padding-top: 80px;
  }
`

const H1 = styled.h1`
  font-size: 1.728rem;
`

export const Head = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12em ${({ padding }) => (padding ? "2em" : 0)} 1em;

  h1 {
    color: var(--white);
    font-weight: normal;
    margin-top: 0;
    min-width: ${({ withFilters }) =>
      withFilters ? "calc(220px + (100% - 220px) / 2)" : "50%"};
    max-width: ${({ withFilters }) =>
      withFilters ? "calc(220px + (100% - 220px) / 2)" : "50%"};
    padding-right: 10%;
  }

  & > div {
    padding 0 1em;
  }

  h2 {
    &:first-child {
      margin-top: 0;
    }
    color: var(--white);
    max-width: 700px;
    font-size: 1.2rem;
  }

  p {
    color: var(--light-grey);
    margin-top: 0;
    max-width: 700px;
    font-size: 1.2rem;
  }

  @media (max-width: 56em) {
    flex-direction: column;
    padding: 6em ${({ padding }) => (padding ? "1em" : 0)} 0em;

    & > div {
      padding: 0;
    }

    h1 {
      max-width: 100%;
    }

    p {
      font-size: 1rem;
    }
  }
`

export const Stats = styled.div`
  margin-top: 8em;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  @media (max-width: 48em) {
    margin-top: 4em;
  }

  @media (max-width: 400px) {
    // flex-direction: column;
  }
`

export const Stat = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 2em;
  margin-bottom: 1em;

  img {
    margin-right: 0.5em;
    width: 2em;
    height: 2em;
    border-radius: 2em;
  }

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 48em) {
    margin-right: 1em;
  }
`

export const Data = styled.div`
  div:first-child {
    font-size: 16px;
    color: var(--light-grey);
  }
  div:last-child {
    font-size: 18px;
    color: var(--white);
  }
`

const TableOfContents = styled.div`
  width: 640px;
  max-width: 100%;
  margin: 2em auto;

  span {
    color: var(--white);
  }

  div {
    cursor: pointer;
    color: var(--light-grey);

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 48em) {
    margin: 2em auto;
  }
`

const ContentsItem = styled.li`
  text-indent: ${props => (props.indent ? "30px" : "0")};
  list-style: none;
`

const ExerciseContainer = styled.div`
  max-width: 640px;
  margin: 1em auto;
  width: 100%;
`

const Body = styled.div`
  margin: 2em auto;

  > h1,
  > h2,
  > h3,
  > div,
  > p,
  > ul,
  > ol,
  > li {
    max-width: 640px !important;
    margin-left: auto;
    margin-right: auto;
    color: var(--white);
  }
  
  img {
    height: 80px;
    width: 80px;
    border-radius: 8px;
    overflow: hidden;
  }

  p,
  li {
    color: var(--light-grey);
  }

  a {
    color: var(--white);
    text-decoration: underline;
  }
`

const Signature = styled.div`
  border-top: 2px solid var(--dark-grey);
  width: 640px;
  max-width: 100%;
  margin: 4em auto 2em;

  p {
    margin: 8px 0 0 0;
    color: var(--light-grey);
  }

  a {
    color: white;
  }

  h3 {
    margin: 1em 0 0;
    color: var(--white);
  }

  img {
    height: 60px;
    margin-top: -1.5em;
    margin-left: 1.5em;
  }
`

export default Content
